@import "../utilities/variables";

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  min-height: 1280px;
  color: #3b3b3b;
  font-size: 24px;
}

p.intro-text {
  font-size: 1.1rem;
  text-align: left;
  color: $font-color;
  font-family: $main-font;
}

p,
h1,
h2,
h3,
h4,
a {
  margin: 0;
  font-weight: 400;
}

a:visited,
a:hover {
  text-decoration: none;
}

a {
  color: $squid-ink;
  text-decoration: underline;
}

a:hover {
  color: $font-color;
  background-color: $color;
  text-decoration: none;
}
