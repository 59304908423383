// colors
$squid-ink: #232f3e;
$bg-color: #fcf4e8da; // rgba(255, 97, 56, 0.8);
$bg-color2: #feefd7; // rgb(246, 233, 218); //rgb(154, 222, 222);
$bg-color-dark: rgba(252, 244, 232, 0.98);
$color: #ff8f00;
$test-color: $color;

// Fonts
$font-color: #232f3e;
$main-font: AmazonEmber;
$font-heavy: AmazonEmberDisplayHeavy;
$font-light: AmazonEmberDisplayLight;
$font-mono: AmazonEmberMono;

// Line-Height
$base-line-height: 1.5;
$header-line-height: 1.25;
