/* ====================== */
/* MLU RESETS */
/* ====================== */
body > div.content-wrapper > div.container-fluid.wbo-header {
  display: none;
}
.wbo-header {
  display: none;
}

footer {
  display: none;
}
