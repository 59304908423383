.bold {
  font-weight: bold;
  text-align: left;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-text {
  text-align: left;
}
