/* ====================== */
/* FONTS */
/* ====================== */

@font-face {
  font-family: AmazonEmberDisplayHeavy;
  src: url("../fonts/AmazonEmberDisplay_He.otf") format("opentype");
}

@font-face {
  font-family: AmazonEmber;
  src: url("../fonts/AmazonEmber_Rg.otf") format("opentype");
}

@font-face {
  font-family: AmazonEmberMono;
  src: url("../fonts/AmazonEmberMono_Rg.otf") format("opentype");
}

@font-face {
  font-family: AmazonEmberDisplayLight;
  src: url("../fonts/AmazonEmberDisplay_Lt.otf") format("opentype");
}
